.container2 {
    width: 100%;
    max-width: 1024px;
    padding: 0 11em;
    margin: 0 auto;
  }
  
  .title {
    text-align: center;
    margin: 2em 0;
  }