#proj1 {
  height: clac(100vh - 4rem);
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.bg {
  position: absolute;
  top: -4rem;
  right: 0;
  z-index: -1;
  object-fit: cover;
  height: 100vh;
}
.sk {
    color: rgb(0, 149, 255)

}
.texx {
    color: rgb(0, 149, 255)

}
.proj1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: large;

}
.container {
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    

}

.resume {
    color: white;
    font-size: medium;
}
.res {
    color: rgb(0, 149, 255);
}
.introContent {
    height: 100vh;
    width: 100vw;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hello {
    font-size: 1.75rem;
    font-weight: 100;


}

.introName {
    color: rgb(0, 149, 255);
}
.introText {
    font-size: 1.5rem;
}


.introPara {
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
}


.btn {
    background: white;
    margin: 3rem;
    padding: 0rem 0rem;
    border:  none;
    border-radius: 2rem;

}
.btnImg {
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;

}

.proj1imgs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction:row;
    
}
.imgg{
    width: 450px;
    height: 380px;
    padding: 2.5rem;
    border-radius: 1rem;
    

}
.imgg2{
    width: 450px;
    height: 380px;
    padding: 2.5rem;
    border-radius: 1rem;
    margin-bottom: 100px;
    

}


@media screen and (max-width:840px) {
    .bg{
        right: -10vw;
    }
    .introContent {
        font-size: 10vw;
    }
    
    .hello{
        font-size: 4.5vw;
    }
}
@media screen and (max-width:480px) {
    .bg{
        right: -20vw;
    }
}